<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">만료일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="searchOptions.expirDate"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">처리구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="drmncyProcessDiv"
                  v-model="searchOptions.drmncyProcessDiv"
                  v-bind="drmncyProcessDivDropdownListProps"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchOptions.memberName"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">휴면회원 개인정보 폐기 현황</div>
              <div class="header-caption">[{{ numberWithCommas(dormantMembersByCount) }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                v-bind="gridOptions"
                :dataSource="dormantMembers"
                @queryCellInfo="onGridQueryCellInfo"
                @actionComplete="onGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import InputText from "@/components/common/text/InputText.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate} from "@/utils/date";
import {numberWithCommas} from "@/utils/number";
import {
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo
} from "@/utils/commonCodes";

export default {
  name: "DestructionOfPersonalInformationOfDormantStatus",
  components: {
    InputDateRange,
    InputText,
    ErpButton,
    EjsGridWrapper,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      searchOptions: {
        expirDate: { from: null, to: null },
        drmncyProcessDiv: "%",
        memberName: null,
      },
      smsSendType: "MEMB_DOPIOD",
      bizName: commonCodesGetStandardInfo("bizName"),
      bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
      kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
      dormantMembers: [],
      dormantMembersByCount: 0,
      commonCodeField: { text: "comName", value: "comCode" },
    };
  },
  created() {
    this.searchOptions.expirDate.from = getFormattedDate(new Date());
    this.searchOptions.expirDate.to = getFormattedDate(new Date());
  },
  computed: {
    isPopupOpened() {
      return false;
    },
    drmncyProcessDivDropdownListProps() {
      const dataSource = commonCodesGetCommonCode("DRMNCY_PROCESS_DIV");
      dataSource.unshift({
        comCode: "%",
        comName: "전체",
      });

      return {
        allowFiltering: false,
        dataSource,
        fields: this.commonCodeField,
      };
    },
    gridOptions() {
      const columns = [
        {
          field: "memberName",
          headerText: "회원명",
          type: "string",
          allowEditing: false,
        },
        {
          field: "webId",
          headerText: "웹ID",
          type: "string",
          allowEditing: false,
        },
        {
          field: "hpNoIdx",
          headerText: "핸드폰 뒷4자리",
          type: "string",
          width: 120,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "stdDate",
          headerText: "기준일자",
          type: "string",
          width: 90,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "expirDate",
          headerText: "만료일자",
          type: "string",
          width: 90,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "smsSendDt",
          headerText: "SMS 발송 일시",
          type: "string",
          width: 150,
          textAlign: "center",
          allowEditing: false,
        },
      ];

      if (commonCodesGetStandardInfo("homepageLoginConf")) {
        columns.push({
          field: "lastLoginDate",
          headerText: "최종 로그인 일자",
          type: "string",
          width: 130,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("resveRceptConf")) {
        columns.push({
          field: "lastResveDate",
          headerText: "최종 예약일자",
          type: "string",
          width: 120,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("cmpnInsertConf")) {
        columns.push({
          field: "lastCmpnInsertDate",
          headerText: "최종 동반자 등록일",
          type: "string",
          width: 140,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("visitInsertConf")) {
        columns.push({
          field: "lastVisitDate",
          headerText: "최종 내장일자",
          type: "string",
          width: 120,
          textAlign: "center",
          allowEditing: false,
        });
      }

      if (commonCodesGetStandardInfo("depositBlceConf")) {
        columns.push({
          field: "depositBlce",
          headerText: "최종 선수금 잔액",
          type: "number",
          format: "N",
          width: 130,
          textAlign: "right",
          allowEditing: false,
        });
      }

      columns.push(
        {
          field: "drmncyProcessDiv",
          headerText: "휴면처리구분",
          type: "string",
          width: 120,
          textAlign: "center",
          allowEditing: false,
          isCommonCodeField: true,
          groupCode: "DRMNCY_PROCESS_DIV",
        },
        {
          field: "drmncyProcessResn",
          headerText: "미처리 사유",
          type: "string",
          width: 180,
          allowEditing: false,
        },
      );

      return {
        provides: [
          Edit,
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns,
      };
    },
  },
  mounted() {},
  methods: {
    numberWithCommas,
    onViewButtonClicked() {
      this.fetchDestructionOfPersonalInformationStatus();
    },
    async fetchDestructionOfPersonalInformationStatus() {
      const data = await GolfErpAPI.fetchDestructionOfPersonalInformationStatus({
        expirDateFrom: this.searchOptions.expirDate.from,
        expirDateTo: this.searchOptions.expirDate.to,
        drmncyProcessDiv: this.searchOptions.drmncyProcessDiv === "%" ? null : this.searchOptions.drmncyProcessDiv,
        memberName: this.searchOptions.memberName,
      });
      console.log('data.===>', data);
      this.dormantMembers = data;
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: { field },
      } = args;

      if (field === "select") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onGridActionComplete() {
      this.dormantMembersByCount = numberWithCommas(
        this.$refs.grid?.getGridBatchCount() || 0
      );
    },
    onExcelButtonClicked() {
      if (!(this.dormantMembers.length > 0)) {
        this.errorToast("조회된 데이터가 없습니다");
        return;
      }

      // 회원명 Column의 Width값이 설정되지 않아 엑셀 저장시 해당 항목이 숨겨지는 부분을 방지하기 위함.
      const excelColumns = JSON.parse(JSON.stringify(this.gridOptions.columns));
      excelColumns.filter(item => item.field === "memberName")[0].width = 120;
      excelColumns.filter(item => item.field === "webId")[0].width = 120;
      this.$refs.grid.excelExport({
        columns: excelColumns,
      });
    },
  },
};
</script>
